import React, { useState } from "react";
import Plot from "react-plotly.js";
import "./styles.css";

function App() {
  const [polynomial, setPolynomial] = useState("");
  const [lowerBound, setLowerBound] = useState(-10);
  const [upperBound, setUpperBound] = useState(10);
  const [data, setData] = useState([]);

  const plotGraph = () => {
    const xValues = [];
    const yValues = [];

    for (let x = lowerBound; x <= upperBound; x += 0.1) {
      xValues.push(x);
      yValues.push(evalPolynomial(polynomial, x));
    }

    setData([
      {
        type: "scatter",
        mode: "lines",
        x: xValues,
        y: yValues,
      },
    ]);
  };

  const evalPolynomial = (poly, x) => {
    let safePoly = poly.replace(/\^/g, "**").replace(/x/g, `(${x})`);
    try {
      return Function(`return ${safePoly};`)();
    } catch (e) {
      console.error("Invalid polynomial", e);
      return 0; // default or handle this differently
    }
  };

  const getTickValues = (lower, upper) => {
    const tickValues = [];
    for (let i = Math.floor(lower); i <= Math.ceil(upper); i++) {
      tickValues.push(i);
    }
    return tickValues;
  };

  return (
    <div className="App">
      <h1>Polynomial Plotter</h1>
      <div>
        <input
          type="text"
          placeholder="Enter polynomial (e.g. x^2 + 3x + 2)"
          onChange={(e) => setPolynomial(e.target.value)}
        />
      </div>
      <div>
        <input
          type="number"
          placeholder="Lower bound"
          value={lowerBound}
          onChange={(e) => setLowerBound(+e.target.value)}
        />
        <input
          type="number"
          placeholder="Upper bound"
          value={upperBound}
          onChange={(e) => setUpperBound(+e.target.value)}
        />
      </div>
      <button onClick={plotGraph}>Plot</button>
      {data.length > 0 && (
        <Plot
          data={data}
          layout={{
            title: "Polynomial Plot",
            width: 500,
            height: 500,
            xaxis: {
              // tickvals: getTickValues(lowerBound, upperBound),
              title: "x",
            },
            yaxis: {
              title: "y",
            },
          }}
        />
      )}
    </div>
  );
}

export default App;
